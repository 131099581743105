import React, { useState } from 'react';
import { IoIosCall } from 'react-icons/io'
import { BiSolidDirectionRight } from 'react-icons/bi'
import { BiSolidBarChartSquare } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const directionMap = 'https://www.google.com/maps/dir//Amma+eye+and+retina+care+centre/@13.0046729,76.0261051,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ba549da45b66e49:0xe86e2a64bcef105d!2m2!1d76.108507!2d13.0046859?entry=ttu'
const Navbar = ({ isOpen, closeDialog }) => {
  const [Open, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!Open);
  };

  return (
    <nav className="bg-white px-8 py-4 navbar w-full">
      <div className="container mx-auto flex items-center justify-between flex-col md:flex-row">
        <div className='flex items-center navbar_logo'>
          <button onClick={toggleNavbar} className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"

            >
              {Open ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
          <div className="navbar_logo text-2xl md:text-3xl pl-3 md:pl-8 truncate cursor-pointer">
            <Link to={'/'} >
              Amma eye and retina care centre
            </Link>
          </div>
        </div>
        {
          Open &&
          <div className='block md:hidden mt-6'>
            <div className='navbar_text flex items-center gap-1 cursor-pointer pb-2'>
              <BiSolidBarChartSquare />
              <span onClick={() => closeDialog(!isOpen)}> Get Quote </span>
            </div >
            <a className='navbar_text flex items-center gap-1 pb-2' href='tel:+9181470 19448'>
              <IoIosCall />
              <span>   Call Now</span>
            </a>
            <a className='navbar_text flex items-center gap-1 pb-2' href={directionMap} rel="noreferrer" target='_blank'>
              <BiSolidDirectionRight />
              <span> Get Directions </span>
            </a>
          </div>
        }

        <div className='hidden md:flex gap-6'>
          <div className='navbar_text flex items-center gap-1 cursor-pointer'>
            <BiSolidBarChartSquare />
            <span onClick={() => closeDialog(!isOpen)}> Get Quote </span>
          </div >
          <a className='navbar_text flex items-center gap-1' href='tel:+9181470 19448'>
            <IoIosCall />
            <span>   Call Now</span>
          </a>
          <a className='navbar_text flex items-center gap-1' href={directionMap} target='_blank'>
            <BiSolidDirectionRight />
            <span> Get Directions </span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
