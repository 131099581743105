import { AiOutlineClose } from 'react-icons/ai';
import React, { useState } from 'react';
const initial={
  name: '',
  phone: '',
  email: '',
  message: '',
}
const ContactForm = ({ isOpen, closeDialog }) => {
  const [formData, setFormData] = useState(initial);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can do something with the formData, like sending it to a server.
    console.log('Form Data:', formData);
    closeDialog();
    setFormData(initial)
  };

  return (
    <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? '' : 'hidden'} bg-black bg-opacity-40`}>
      <div className="bg-white p-4 rounded shadow-md relative">
        <div className='flex justify-between items-center w-full'>
          <h2 className="text-1xl text-gray-600">Get Quote</h2>
          <button className="text-gray-500 hover:text-gray-800" onClick={closeDialog}>
            <AiOutlineClose size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="my-4 w-[300px] md:w-[500px]">
            <label htmlFor="name" className="block text-sm font-medium text-gray-600 mb-1">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-600 mb-1">Number</label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Your Phone"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-600 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Your Email"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-normal text-gray-600 mb-1">How can we help you?</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              rows="4"
              placeholder="I was wondering about availability and rates. I need help with the following:"
            ></textarea>
          </div>
          <div className='flex justify-end'>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
