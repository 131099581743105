import React, { useState } from 'react'
import homeImage from '../assest/images/home.jpeg'
import Testimonial from './Testimonial'
import image1 from '../assest/gallery/1.jpeg';
import image2 from '../assest/gallery/2.jpeg';
import image3 from '../assest/gallery/3.jpeg';
import image4 from '../assest/gallery/4.jpeg';
import image5 from '../assest/gallery/5.jpeg';
import image6 from '../assest/gallery/6.jpeg';
import image7 from '../assest/gallery/7.jpeg';
import image8 from '../assest/gallery/8.jpeg';
import image9 from '../assest/gallery/9.jpeg';
import image10 from '../assest/gallery/10.jpeg';
import image11 from '../assest/gallery/11.jpeg';
import image12 from '../assest/gallery/12.jpeg';
import image13 from '../assest/gallery/13.jpeg';
import image14 from '../assest/gallery/14.jpeg';
import image15 from '../assest/gallery/15.jpeg';
import image16 from '../assest/gallery/16.jpeg';
import image17 from '../assest/gallery/17.jpeg';
import image18 from '../assest/gallery/18.jpeg';
import image20 from '../assest/gallery/20.jpeg';
import image21 from '../assest/gallery/21.jpeg';
import image22 from '../assest/gallery/22.jpeg';
import image23 from '../assest/gallery/23.jpeg';
import image24 from '../assest/gallery/24.jpeg';
import image25 from '../assest/gallery/25.jpeg';
import image26 from '../assest/gallery/26.jpeg';
import image27 from '../assest/gallery/27.jpeg';
import image28 from '../assest/gallery/28.jpeg';
import image29 from '../assest/gallery/29.jpeg';




import maplocation from '../assest/images/location.png'
import ContactForm from './Contact'


const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29
];

const directionMap = 'https://www.google.com/maps/dir//Amma+eye+and+retina+care+centre/@13.0046729,76.0261051,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ba549da45b66e49:0xe86e2a64bcef105d!2m2!1d76.108507!2d13.0046859?entry=ttu'
const map = 'https://www.google.com/maps/place/Amma+eye+and+retina+care+centre/@13.0046859,76.108507,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba549da45b66e49:0xe86e2a64bcef105d!8m2!3d13.0046859!4d76.108507!16s%2Fg%2F11fs7tvnsy?entry=ttu'
const writeReview = 'https://www.google.com/search?hl=en-IN&gl=in&q=Amma+eye+and+retina+care+centre,+Sj,+Park+Rd,+near+Vasavi+school,+Krishnaraja+Pura,+Hassan,+Karnataka+573201&ludocid=16748370676392333405&lsig=AB86z5XA2MbmhnSCT554GESah9N2#lrd=0x3ba549da45b66e49:0xe86e2a64bcef105d,1'
const reviewUrl = 'https://www.google.com/search?hl=en-IN&gl=in&q=Amma+eye+and+retina+care+centre,+Sj,+Park+Rd,+near+Vasavi+school,+Krishnaraja+Pura,+Hassan,+Karnataka+573201&ludocid=16748370676392333405&lsig=AB86z5XA2MbmhnSCT554GESah9N2#lrd=0x3ba549da45b66e49:0xe86e2a64bcef105d,3'

function Body({ isOpen, closeDialog }) {

    return (
        <div className='w-full flex justify-center'>
            <div className='w-full md:w-[80%] pt-6 md:pt-12'>
                <div className='flex md:flex-col flex-col-reverse'>
                    <div className='flex justify-center items-center flex-col'>
                        <div className='navbar_logo text-3xl md:text-6xl text-center'> Amma eye and retina care centre</div>
                        <p className='text-center py-4 text-gray-500'>
                            Hospital in Krishnaraja Pura <br />
                            Open today until 9:00 PM
                        </p>
                        <button className='button-get-qoute px-6 py-3 mt-2 hover:bg-indigo-900 rounded-sm' onClick={() => closeDialog(!isOpen)}>
                            GET QUOTE
                        </button>
                    </div>
                    <div>
                        <img src={homeImage} alt='home' />
                    </div>
                </div>
                <div className='flex justify-center items-center gap-2 flex-col my-4'>
                    <p className="w-[60px] border-b-2 border-gray-700"></p>
                    <p className='text-1xl md:text-2xl libra-font text-gray-700'>TESTIMONIALS</p>
                </div>
                <Testimonial />
                <div className='flex justify-center items-center gap-2 my-4'>
                    <div className='flex gap-6'>
                        <div>
                            <a href={reviewUrl} className='text-1xl navbar_logo'>WRITE A REVIEW</a>
                            <hr className="border-t-1 border-gray-500 my-1" />
                        </div>
                        <div>
                            <a href={writeReview} className='text-1xl navbar_logo'>READ MORE</a>
                            <hr className="border-t-1 border-gray-500 my-1" />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-2 flex-col my-8'>
                    <p className="w-[60px] border-b-2 border-gray-700"></p>
                    <p className='text-1xl md:text-2xl libra-font text-gray-700'>GALLERY</p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mx-4'>
                    {images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`image${index + 1}`}
                                className="w-[350px] h-[200px] object-cover shadow-md"
                            />
                        </div>
                    ))}
                </div>
                <div className='flex justify-center items-center gap-2 flex-col my-4'>
                    <p className="w-[60px] border-b-2 border-gray-700"></p>
                    <p className='text-1xl md:text-2xl libra-font text-gray-700'>CONTACT US</p>
                </div>
                <a href={map} target='_blank' className='my-2 flex justify-center'>
                    <img src={maplocation} className='w-[90%] md:w-[100%] h-[200px] md:h-[400px]' />
                </a>
                <div className='my-6 flex gap-4 flex-col md:flex-row justify-center md:justify-between mx-4'>
                    <div>
                        <p className='text-2xl text-gray-700'>Contact</p>
                        <button className='button-get-qoute px-6 py-3 my-2 hover:bg-indigo-900 rounded-sm'>
                            <a href='tel:+9181470 19448' target='_blank'>   CALL NOW</a>
                        </button>
                        <p className='text-gray-600'>081470 19448</p>
                    </div>
                    <div>
                        <p className='text-2xl text-gray-700'>Address</p>
                        <button className='button-get-qoute px-6 py-3 my-2 hover:bg-indigo-900 rounded-sm'>
                            <a href={directionMap} target='_blank'>GET DIRECTIONS </a>
                        </button>
                        <p className='text-gray-600'>Sj <br />
                            Park Road <br />
                            near Vasavi school <br />
                            Krishnaraja Pura  <br />
                            Hassan, Karnataka 573201 <br />
                            India
                        </p>
                    </div>
                    <div>
                        <p className='text-2xl text-gray-700'>Business Hours</p>
                        <p className='text-gray-600 mt-3'>
                            Mon:	9:00 AM – 9:00 PM <br />
                            Tue:	9:00 AM – 9:00 PM <br />
                            Wed:	9:00 AM – 9:00 PM <br />
                            Thu:	9:00 AM – 9:00 PM <br />
                            Fri:	9:00 AM – 9:00 PM <br />
                            Sat:	9:00 AM – 9:00 PM <br />
                            Sun:	9:00 AM – 9:00 PM
                        </p>
                    </div>
                </div>
                <ContactForm isOpen={isOpen} closeDialog={closeDialog} />
            </div>
        </div >
    )
}

export default Body
