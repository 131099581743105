import './App.css';
import { useState } from 'react';
import Body from './components/Body';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

function App() {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }
  return (
    <div className="">
      <Router>
        <Navbar closeDialog={handleClose} isOpen={open} />
        <Body closeDialog={handleClose} isOpen={open} />
      </Router>

    </div>
  );
}

export default App;
