import React from 'react'
import { AiTwotoneStar } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
function Testimonial() {
    return (
        <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div className='mx-4'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-6 items-center'>
                        <div className='flex gap-1'>
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                        </div>
                        <p className='text-1xl text-gray-400'>a year ago</p>
                    </div>
                    <BsThreeDotsVertical />
                </div>
                <p className='w-full text-1xl text-gray-500 text-start py-4'>
                    I highly recommend this place to any eye patient.
                    The doctor has thorough knowledge of the subject and his
                    customers will not only be satisfied with the excellent results but also the very nominal charges.
                    It's a one stop clinic for all your eye problems .
                </p>
                <p className='navbar_logo font-bold text-1xl'>- Prasanna S</p>
            </div>
            <div className='mx-4'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-6 items-center'>
                        <div className='flex gap-1'>
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                        </div>
                        <p className='text-1xl text-gray-400'>6 month ago</p>
                    </div>
                    <BsThreeDotsVertical />
                </div>
                <p className='w-full text-1xl text-gray-500 text-start py-4'>
                    Gud service and hospital is very clean staff are friendly
                </p>
                <p className='navbar_logo font-bold text-1xl'> - Nayanagowda</p>
            </div>
            <div className='mx-4'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-6 items-center'>
                        <div className='flex gap-1'>
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                            <AiTwotoneStar color='rgb(251,188,4)' size={20} />
                        </div>
                        <p className='text-1xl text-gray-400'>a year ago</p>
                    </div>
                    <BsThreeDotsVertical />
                </div>
                <p className='w-full text-1xl text-gray-500 text-start py-4'>
                    Really good hospital doctors and management are service oriented free services
                    to poor people with lot of concession to advanced retina treatment compared to others
                </p>
                <p className='navbar_logo font-bold text-1xl'> - Babu B</p>
            </div>
        </div>

    )
}

export default Testimonial
